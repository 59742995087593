import React from "react";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import "./src/style/style.scss";

import ProviderWapper from "./src/context/context";

export const wrapRootElement = ({ element }) => (
  <ProviderWapper>{element}</ProviderWapper>
);
