import { extendTheme } from "@chakra-ui/react";
import { defineStyleConfig } from "@chakra-ui/react";

import { selectTheme } from "./select";
import { inputTheme } from "./input";
import { radioTheme } from "./radio";
import { checkboxTheme } from "./checkbox";
import { buttonTheme } from "./button";
import { progressTheme } from "./progress";

const FormLabel = defineStyleConfig({
  baseStyle: {
    fontSize: "lg",
    color: "gray.900",
    fontWeight: 600,
    _dark: {
      color: "white",
    },
  },
});

const theme = {
  breakpoints: {
    base: "0em", // 0px
    sm: "48em", // ~768px
    md: "64em", // ~1024px
    lg: "80em", // ~1280px
    xl: "96em", // ~1536px
  },
  colors: {
    warmgray: {
      50: "#FAF5F2",
      100: "#F0E8E4",
      200: "#E6DAD3",
      300: "#DBCDC5",
      400: "#BFB1A8",
      500: "#9C928C",
      600: "#827A75",
      700: "#615B57",
      800: "#3B3735",
      900: "#242220",
    },
  },
  fonts: {
    heading: `'DM Sans', sans-serif;`,
    body: `'DM Sans', sans-serif;`,
  },

  components: {
    FormLabel,
    Button: buttonTheme,
    Select: selectTheme,
    Input: inputTheme,
    Radio: radioTheme,
    Checkbox: checkboxTheme,
    Progress: progressTheme,
  },
};

export default extendTheme(theme);

export const baseTheme = {};
