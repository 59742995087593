import { progressAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(progressAnatomy.keys);

const complete = definePartsStyle({
  track: {
    bg: "warmgray.200",
    borderRadius: "32px",
    border: 0,
    _dark: {
      bg: "warmgray.700",
    },
  },
  filledTrack: {
    bg: "green.600",
    borderRadius: "32px",
    _dark: {
      bg: "green.400",
    },
  },
});

const brandPrimary = definePartsStyle({
  track: {
    bg: "warmgray.200",
    borderRadius: "32px",
    border: 0,
    _dark: {
      bg: "warmgray.700",
    },
  },
  filledTrack: {
    bg: "purple.600",
    _dark: {
      bg: "purple.200",
    },
  },
});

export const progressTheme = defineMultiStyleConfig({
  variants: { brandPrimary, complete },
  defaultProps: {
    variant: "brandPrimary",
  },
});
