import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const box = definePartsStyle({
  control: defineStyle({
    rounded: 2,
    margin: 0,
    order: 2,
  }),
  container: defineStyle({
    border: "2px solid",
    borderColor: "gray.300",
    padding: 4,
    borderRadius: 4,
    flexFlow: "column",
    alignItems: "flex-start",
    _checked: {
      bg: "purple.100",
      borderColor: "purple.500",
    },
  }),
  label: defineStyle({
    color: "gray.700",
    fontSize: "md",
    p: 0,
    m: 0,
    paddingBottom: 8,
    order: 0,
    _dark: {
      color: "white",
    },
  }),
});

const boxImage = definePartsStyle({
  control: defineStyle({
    rounded: "full",
  }),
  container: defineStyle({
    border: "2px solid",
    borderColor: "gray.300",
    padding: 2,
    borderRadius: 4,
    alignItems: "flex-start",
  }),
  label: defineStyle({
    color: "gray.700",
    fontSize: "md",
    p: 0,
    m: 0,
    paddingBottom: 2,
    order: 0,
    _dark: {
      color: "white",
    },
  }),
});

const brandPrimary = definePartsStyle({
  container: {
    border: "2px solid",
    borderColor: "gray.300",
    padding: 4,
    borderRadius: 16,
    _checked: {
      borderColor: "purple.500",
      bg: "purple.100",
    },
  },
  control: {
    borderColor: "gray.300",
    boxSize: 5,
    marginRight: 4,
  },
  label: {
    color: "gray.900",
    _dark: {
      color: "white",
    },
    _checked: {
      _dark: {
        color: "gray.900",
      },
    },
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: { brandPrimary, box, boxImage },
  defaultProps: {
    size: "lg",
    variant: "brandPrimary",
    colorScheme: "purple",
  },
});
