import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const xl = defineStyle({
  fontSize: "lg",
  px: "6",
  h: "16",
  borderRadius: "md",
});

const small = defineStyle({
  fontSize: "md",
  rounded: 8,
  fontWeight: 400,
  h: 12,
});

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    rounded: 16,
    fontWeight: 600,
  },
  variants: { small },
  sizes: { xl },
  defaultProps: {
    size: "xl",
    colorScheme: "purple",
    variant: "solid",
  },
});
