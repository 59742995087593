import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, auth } from "../components/firebase";

const defaultContext = {
  currentUser: null,
  loading: true,
  question: 0,
};

export const Context = createContext(defaultContext);
export const useAuth = () => useContext(Context);

const ProviderWrapper = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(-1);
  const [total, setTotal] = useState(0);
  const [edit, setEdit] = useState(false);
  const [answers, setAnswers] = useState({
    about_you: {},
    about_your_job: {},
    workplace_challenges: {},
    impact: {},
  });

  const [userRecommendations, setRecommendations] = useState({
    support_worker: [],
    assistive_tech: [],
    assistive_software: [],
    mental_health: [],
    equipment: [],
    coach: [],
  });

  const saveToStorage = (type, response) => {
    if (type === "answers") {
      setAnswers(response);
      localStorage.setItem(
        `sita_assessment_${currentUser.uid}`,
        JSON.stringify(response)
      );
    } else {
      setRecommendations(response);
      localStorage.setItem(
        `sita_recommendations_${currentUser.uid}`,
        JSON.stringify(response)
      );
    }
  };

  const saveToDb = async (user) => {
    const u = user ? user.uid : currentUser.uid;

    const docRef = doc(db, "new_users", u);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const updatedAnswers = {
        ...answers,
        last_saved: Date.now(),
      };

      const updatedUser = {
        ...docSnap.data(),
        assessment: updatedAnswers,
      };

      try {
        await setDoc(docRef, updatedUser, { merge: true });
        return true;
      } catch (e) {
        return false;
      }
    }
  };

  const saveRecommendations = async (user) => {
    const u = user ? user.uid : currentUser.uid;

    const docRef = doc(db, "users", u);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const updatedRecs = {
        ...userRecommendations,
        last_saved: Date.now(),
      };

      const updatedUser = {
        ...docSnap.data(),
        recommendations: updatedRecs,
      };

      try {
        await setDoc(docRef, updatedUser, { merge: true });
        return true;
      } catch (e) {
        return false;
      }
    }
  };

  // get data from db and navigate to dashboard. (happens on login)

  const getData = async (user) => {
    const docRef = doc(db, "new_users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let data = docSnap.data();

      if (data.assessment) {
        saveAnswers(data.assessment, user);
      } else {
        const newA = {
          ...(data.assessment || {}),
          about_you: {},
          about_your_job: {},
          workplace_challenges: {},
          impact: {},
        };

        saveAnswers(newA, user, true);
      }

      if (data.recommendations) {
        setRecommendations(data.recommendations);
      }

      return true;
    } else {
      return false;
    }
  };

  const saveAnswers = (answers, user, pushToDB) => {
    if (user) {
      localStorage.setItem(
        `sita_assessment_${user.uid}`,
        JSON.stringify(answers)
      );
      setAnswers(answers);
      setLoading(false);
    }

    if (pushToDB) {
      saveToDb(user);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);

        if (user) {
          if (localStorage.getItem(`sita_assessment_${user.uid}`)) {
            setAnswers(
              JSON.parse(localStorage.getItem(`sita_assessment_${user.uid}`))
            );
            setLoading(false);
          } else {
            if (user) {
              getData(user);
            }
          }
        }
      });
    } else {
      if (localStorage.getItem(`sita_recommendations_${currentUser.uid}`)) {
        setRecommendations(
          JSON.parse(
            localStorage.getItem(`sita_recommendations_${currentUser.uid}`)
          )
        );
      }
    }
  }, [currentUser]);

  return (
    <Context.Provider
      value={{
        currentUser,
        loading,
        answers,
        saveToStorage,
        saveToDb,
        getData,
        page,
        setPage,
        total,
        setTotal,
        setEdit,
        edit,
        userRecommendations,
        saveRecommendations,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useAuthValue() {
  return useContext(Context);
}

export default ProviderWrapper;
