import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const brandPrimary = definePartsStyle({
  container: {
    border: "2px solid",
    borderColor: "gray.300",
    padding: 4,
    borderRadius: 16,
    _checked: {
      borderColor: "purple.500",
      bg: "purple.100",
    },
  },
  control: {
    borderColor: "gray.300",
    _checked: {
      bg: "purple.500",
      borderColor: "purple.500",
    },
  },
  label: {
    color: "gray.900",
    _dark: {
      color: "white",
    },
    _checked: {
      _dark: {
        color: "gray.900",
      },
    },
  },
});

export const radioTheme = defineMultiStyleConfig({
  variants: { brandPrimary },
  defaultProps: {
    size: "lg",
    variant: "brandPrimary",
  },
});
