import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const brandPrimary = definePartsStyle({
  field: {
    background: "warmgray.100",
    color: "gray.700",
    border: "1px dashed",
    borderColor: "gray.200",
    borderRadius: "md",
  },
  icon: {
    color: "gray.700",
  },
});

export const selectTheme = defineMultiStyleConfig({
  variants: { brandPrimary },
  defaultProps: {
    size: "lg",
    variant: "brandPrimary",
  },
});
